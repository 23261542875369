import React from "react";
import myImage from '../assets/aggregatedHeatMap.png'

const ImageComponent = ({ imageData }) => {
  if (!imageData) {
    if (myImage) {
      // Render a loading indicator or placeholder while imageData is being fetched
      return (<div>
        <img
          // src={`data:image/png;base64,${myImage}`}
          src={myImage}
          alt="Time Series should show here"
          style={{ width: "500px" }}
          loading="lazy"
        // controls
        />
      </div>);
    }
    else {
      return <div>Image Should Show here</div>;
    }
  }
  return (
    <div>
      <img
        src={`data:image/png;base64,${imageData}`}
        // src={require("/home/husse408/iHARP New Project/iHARPVFullStack/iHARPV/FrontEnd/src/assets/timeSeriesResult.png")}
        alt="Time Series should show here"
        style={{ width: "500px" }}
        loading="lazy"
      // controls
      />
    </div>
  );
};

export default ImageComponent;

import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const CollapsibleCardLower = ({ title, children, expanded, onExpandClick }) => {
  return (
    <Card sx={{ maxHeight: '295px' }}>
      <CardHeader sx={{ padding: '0px 0px' }} />
      <CardActions disableSpacing sx={{ padding: '1px 1px', maxHeight: '15px', backgroundColor: 'black' }}>
        <IconButton onClick={onExpandClick} aria-expanded={expanded} sx={{ color: 'white', backgroundColor: 'black' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ padding: '0px 0px' }}>
          {children}
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CollapsibleCardLower;

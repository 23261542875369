import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import Box from "@mui/joy/Box";
import TimeSeriesComponent from "./components/TimeSeriesComponent";
import HeatMapComponent from "./components/HeatMapComponent";
import MyMap from "./components/Map";
// import Sidebar from "./components/Sidebar";
import "./App.css";
import AreasComponentMap from './components/AreasComponentMap';
import TableComponent from "./components/TableComponent";
import CollapsibleCard from "./components/CollapsibleCard";
import CollapsibleCardLower from "./components/CollapsibleCardLower";
import TimesComponent from "./components/Times";
import TableTimesComponent from "./components/TableTimesComponent";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import VariablesDropDown from "./components/VariablesDropDown";
import Button from "react-bootstrap/Button";
import { useContext, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from "react-bootstrap/Form";
import ProgressBar from "react-bootstrap/ProgressBar";
import ImageComponent from "./components/ImageComponent";
import "bootstrap/dist/css/bootstrap.min.css";
import { BoundsContext } from "./components/BoundsContext";
import ComparisonsDropDown from "./components/ComparisonsDropDownComponent";
import SecondAggDropDown from "./components/SecondAggDropDownComponent";
import Divider from '@mui/material/Divider';
import QuantityInput from "./components/NumberInputComponent";
import DownloadDropDownComponent from "./components/DownloadDropDownComponent";
const drawerWidth = 375;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: -20,
    marginRight: -21,
    marginTop: -85,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export default function App() {
  dayjs.extend(utc);
  // dayjs.extend(timezone);
  // const classes = useStyles();
  const [expandedFirst, setExpandedFirst] = useState(true);
  const [expandedSecond, setExpandedSecond] = useState(true);
  const [expandedThird, setExpandedThird] = useState(true);
  const [expandedFourth, setExpandedFourth] = useState(true);

  const handleExpandClickFirst = (newValue) => {
    setExpandedFirst(newValue);
  };
  const handleExpandClickSecond = (newValue) => {
    setExpandedSecond(newValue);
  };
  const handleExpandClickThird = () => {
    setExpandedThird(!expandedThird);
  };
  const handleExpandClickFourth = () => {
    setExpandedFourth(!expandedFourth);
  };

  const [open, setOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [jsonFile, setJsonFile] = useState(null);
  const [jsonFileTimeQuery, setJsonFileTimeQuery] = useState(null);
  const [requestType, setrequestType] = useState("");
  const [tableDataM, setTableDataM] = useState(null);
  const [tableDataTimes, setTableDataTimes] = useState(null);

  // const [variable, setVariable] = React.useState("2m Temperature");
  // const [selectedStartDateTime, setSelectedStartDateTime] = useState(dayjs("2023-01-01T00")); // Initialize selectedStartDateTime with null
  // const [selectedEndDateTime, setSelectedEndDateTime] = useState(dayjs("2023-01-15T01")); // Initialize selectedEndDateTime with null
  // const [videoUrl, setVideoUrl] = useState("");
  // const [responseRecieved, setresponseRecieved] = useState("");
  const maxDate = dayjs("2023-12-31T23");
  const minDate = dayjs("2014-01-01 00");
  const [variable, setVariable] = React.useState("2m Temperature");
  const [comparison, setComparison] = React.useState("<");
  const [secondAgg, setSecondAgg] = React.useState("any");
  const [downloadOption, setDownloadOption] = React.useState("");

  const [myValue, setMyValue] = React.useState(225);


  const [progress, setProgress] = useState(100); // State to manage progress
  const [progressDesc, setProgressDesc] = useState("Initial Data Loaded"); // State to manage progress

  const [videoRecieved, setVideoReceived] = useState(null);
  const [imageRecieved, setImageRecieved] = useState(null); // Initialize selectedEndDateTime with null
  const [heatmapimageRecieved, setHeatmapImageRecieved] = useState(null); // Initialize selectedEndDateTime with null

  const [tableRecieved, setTableRecieved] = useState([]); // Initialize selectedEndDateTime with null
  const [tableTimesRecieved, setTableTimesRecieved] = useState([]); // Initialize selectedEndDateTime with null

  const { drawnShapeBounds, setDrawnShapeBounds } = useContext(BoundsContext);
  const classes = useStyles();
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(dayjs("2023-01-01T00:00")); // Initialize selectedStartDateTime with null
  const [selectedEndDateTime, setSelectedEndDateTime] = useState(dayjs("2023-01-30T00:00")); // Initialize selectedEndDateTime with null

  const [heatmapImageSrc, setheatmapImageSrc] = useState(null);

  const [formData, setFormData] = useState({
    requestType: "",
    variable: "2m Temperature",
    startDateTime: selectedStartDateTime,
    endDateTime: selectedEndDateTime,
    temporalLevel: "daily",
    aggLevel: "mean",
    spatialLevel: "2.0",
    north: 84,
    south: 52,
    east: 104,
    west: -111,
    secondAgg: "",
    comparison: "",
    value: 285,
    downloadOption: "",

  });
  console.log(formData);

  const [areaRecieved, setAreaRecieved] = useState(null); // Initialize selectedEndDateTime with null
  const [timeRecieved, setTimeRecieved] = useState(null); // Initialize selectedEndDateTime with null

  const handleValueChange = (newValue) => {
    setMyValue(newValue);
  };
  const handleChangeDropDown = (event) => {
    setVariable(event.target.value);
  };
  const handleChangeComparisonDropDown = (event) => {
    setComparison(event.target.value);
  };
  const handleChangeDownloadDropDown = (event) => {
    setDownloadOption(event.target.value);
  };
  const handleChangeSecondAggDropDown = (event) => {
    setSecondAgg(event.target.value);
  };

  // console.log("Inside Sidebar myvale", myValue);
  const handleChange = (e) => {
    let myValue;
    const { name, value } = e.target;
    // Convert the input value to a number
    if (
      name === "north" ||
      name === "south" ||
      name === "east" ||
      name === "west"
    ) {
      let numericValue = parseFloat(value);

      // Define the range boundaries based on the input name
      let min, max;
      if (name === "north" || name === "south") {
        min = -90;
        max = 90;
      } else if (name === "east" || name === "west") {
        min = -180;
        max = 180;
      }

      // Clamp the value to the range
      numericValue = Math.min(Math.max(numericValue, min), max);
      myValue = numericValue;
      // Update the form data with the clamped value

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValue,
      }));
    } else {
      // For other inputs, update the form data as usual
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
      // setTemporalLevelSelected(value !== "");
    }
    if (drawnShapeBounds) {
      setDrawnShapeBounds((prevBounds) => ({
        _southWest: {
          lat: name === "south" ? myValue : prevBounds._southWest.lat,
          lng: name === "west" ? myValue : prevBounds._southWest.lng,
        },
        _northEast: {
          lat: name === "north" ? myValue : prevBounds._northEast.lat,
          lng: name === "east" ? myValue : prevBounds._northEast.lng,
        },
      }));
    } else {
      if (formData.south && formData.north && formData.east && formData.west) {
        setDrawnShapeBounds(() => ({
          _southWest: {
            lat: formData.south,
            lng: formData.west,
          },
          _northEast: {
            lat: formData.north,
            lng: formData.east,
          },
        }));
      }
    }
    // setTemporalLevelSelected(value !== "");
  };
  const handleAreas = async (e) => {
    if (e) e.preventDefault();
    if (formData.variable === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a variable before proceeding..."
      );
      return; // Exit the function early
    }
    else if (selectedEndDateTime.isBefore(selectedStartDateTime)) {
      alert(
        "ERROR: End Date Time Must Be After Than Start Date Time"
      );
      return; // Exit the function early
    }
    else if (formData.secondAgg === "") {
      alert(
        "ERROR: Please select second aggregation level method before proceeding..."
      );
      return; // Exit the function early
    }
    else if (formData.comparison === "") {
      alert(
        "ERROR: Please select comparison operator before proceeding..."
      );
      return; // Exit the function early
    }
    else if (formData.temporalLevel === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a temporal level resolution before proceeding..."
      );
      return; // Exit the function early
    } else if (
      formData.temporalLevel !== "hourly" &&
      formData.aggLevel === ""
    ) {
      alert("ERROR: Please select an aggregation method before proceeding...");
      return; // Exit the function early
    } else if (!selectedStartDateTime) {
      alert("ERROR: Please select a start date and time before proceeding.");
      return; // Exit the function early
    } else if (!selectedEndDateTime) {
      alert("ERROR: Please select an end date and time before proceeding..");
      return; // Exit the function early
    } else if (
      isNaN(formData.north) ||
      isNaN(formData.south) ||
      isNaN(formData.east) ||
      isNaN(formData.west) ||
      (formData.north > 90) ||
      (formData.south < -90) ||
      (formData.west < -180) ||
      (formData.east > 180)
    ) {
      alert(
        "ERROR: Please select an area on the map or enter FOUR coordinates of interest manually(S,N,W,E) before proceeding..."
      );
      alert(
        "Coordinates should be between -90:90 and -180:180 for (S,N,W,E) respectively..."
      );
      return; // Exit the function early
    }

    formData.requestType = "Area Query";
    formData.startDateTime = selectedStartDateTime;
    formData.endDateTime = selectedEndDateTime;

    try {
      // setProgress(20);
      // setProgressDesc("Getting Areas");
      // Send request to the backend to fetch both time series data and image data
      const response = await fetch("areas/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const jsonData = await response.json();
        setAreaRecieved(jsonData.plotlyData);
        setTableRecieved(jsonData.dfData);
      } else {
        const errorResponse = await response.json();
        setProgress(5);
        setProgressDesc(errorResponse.error, response.status);
        console.error(
          "Failed to fetch areas. HTTP status:",
          response.status,
          "Error message:",
          errorResponse.error
        );
      }
    } catch (error) {
      console.error("Error requesting Time Series:", error);
    }
  };
  const handleTimes = async (e) => {
    if (e) e.preventDefault();
    // if (fo)
    if (formData.variable === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a variable before proceeding..."
      );
      return; // Exit the function early
    }
    else if (selectedEndDateTime.isBefore(selectedStartDateTime)) {
      alert(
        "ERROR: End Date Time Must Be After Than Start Date Time"
      );
      return; // Exit the function early
    }
    else if (formData.secondAgg === "") {
      alert(
        "ERROR: Please select second aggregation level method before proceeding..."
      );
      return; // Exit the function early
    }
    else if (formData.comparison === "") {
      alert(
        "ERROR: Please select comparison operator before proceeding..."
      );
      return; // Exit the function early
    }
    else if (formData.value === "") {
      alert(
        "ERROR: Please Enter Value before proceeding..."
      );
      return; // Exit the function early
    }
    else if (formData.temporalLevel === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a temporal level resolution before proceeding..."
      );
      return; // Exit the function early
    } else if (
      formData.temporalLevel !== "hourly" &&
      formData.aggLevel === ""
    ) {
      alert("ERROR: Please select an aggregation method before proceeding...");
      return; // Exit the function early
    } else if (!selectedStartDateTime) {
      alert("ERROR: Please select a start date and time before proceeding.");
      return; // Exit the function early
    } else if (!selectedEndDateTime) {
      alert("ERROR: Please select an end date and time before proceeding..");
      return; // Exit the function early
    } else if (
      isNaN(formData.north) ||
      isNaN(formData.south) ||
      isNaN(formData.east) ||
      isNaN(formData.west) ||
      (formData.north > 90) ||
      (formData.south < -90) ||
      (formData.west < -180) ||
      (formData.east > 180)
    ) {
      alert(
        "ERROR: Please select an area on the map or enter FOUR coordinates of interest manually(S,N,W,E) before proceeding..."
      );
      alert(
        "Coordinates should be between -90:90 and -180:180 for (S,N,W,E) respectively..."
      );
      return; // Exit the function early
    }

    formData.requestType = "Times Query";
    formData.startDateTime = selectedStartDateTime;
    formData.endDateTime = selectedEndDateTime;

    try {
      setProgress(20);
      setProgressDesc("Finding Time Units");
      // Send request to the backend to fetch both time series data and image data
      const response = await fetch("times/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const jsonData = await response.json();
        setTimeRecieved(jsonData.plotlyData);
        setTableTimesRecieved(jsonData.dfData);
      } else {
        const errorResponse = await response.json();
        setProgress(5);
        setProgressDesc(errorResponse.error, response.status);
        console.error(
          "Failed to fetch times. HTTP status:",
          response.status,
          "Error message:",
          errorResponse.error
        );
      }
    } catch (error) {
      console.error("Error requesting Time Series:", error);
    }
  };
  const handleDownload = async (e) => {
    if (e) e.preventDefault();
    if (formData.variable === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a variable before proceeding..."
      );
      return; // Exit the function early
    }
    else if (selectedEndDateTime.isBefore(selectedStartDateTime)) {
      alert(
        "ERROR: End Date Time Must Be After Than Start Date Time"
      );
      return; // Exit the function early
    }
    else if (formData.temporalLevel === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a temporal level resolution before proceeding..."
      );
      return; // Exit the function early
    } else if (!selectedStartDateTime) {
      alert("ERROR: Please select a start date and time before proceeding.");
      return; // Exit the function early
    } else if (!selectedEndDateTime) {
      alert("ERROR: Please select an end date and time before proceeding..");
      return; // Exit the function early
    } else if (
      isNaN(formData.north) ||
      isNaN(formData.south) ||
      isNaN(formData.east) ||
      isNaN(formData.west) ||
      (formData.north > 90) ||
      (formData.south < -90) ||
      (formData.west < -180) ||
      (formData.east > 180)
    ) {
      alert(
        "ERROR: Please select an area on the map or enter FOUR coordinates of interest manually(S,N,W,E) before proceeding..."
      );
      alert(
        "Coordinates should be between -90:90 and -180:180 for (S,N,W,E) respectively..."
      );
      return; // Exit the function early
    }

    formData.requestType = "Download";
    formData.startDateTime = selectedStartDateTime;
    formData.endDateTime = selectedEndDateTime;
    try {
      setProgress(20);
      setProgressDesc("Downloading Data");
      // console.log(formData);
      // Send request to the backend to fetch both time series data and image data
      const response = await fetch("download/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        // Parse the response as JSON
        const responseData = await response.json();
        const fileName = responseData.fileName;
        alert("Receive file name: " + fileName);
        const fileUrl = `static/media/${fileName}`;
        window.open(fileUrl);
        setProgress(100);
        setProgressDesc("Data Served");
      } else {
        const errorResponse = await response.json();
        setProgress(5);
        setProgressDesc(errorResponse.error, response.status);
        console.error(
          "Failed to download data. HTTP status:",
          response.status,
          "Error message:",
          errorResponse.error
        );
      }
    } catch (error) {
      console.error("Error requesting Time Series:", error);
    }
  };
  // console.log(downloadOption);
  const handleDownloadAreasTimes = async (e) => {
    if (e) e.preventDefault();
    if (formData.variable === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a variable before proceeding..."
      );
      return; // Exit the function early
    }
    else if (downloadOption === "") {
      alert(
        "ERROR: Please select Areas or Times from Download Dropdown....."
      );
      return; // Exit the function early
    }
    else if (formData.secondAgg === "") {
      alert(
        "ERROR: Please select second aggregation level method before proceeding..."
      );
      return; // Exit the function early
    }
    else if (formData.comparison === "") {
      alert(
        "ERROR: Please select comparison operator before proceeding..."
      );
      return; // Exit the function early
    }
    else if (selectedEndDateTime.isBefore(selectedStartDateTime)) {
      alert(
        "ERROR: End Date Time Must Be After Than Start Date Time"
      );
      return; // Exit the function early
    }
    else if (formData.temporalLevel === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a temporal level resolution before proceeding..."
      );
      return; // Exit the function early
    } else if (!selectedStartDateTime) {
      alert("ERROR: Please select a start date and time before proceeding.");
      return; // Exit the function early
    } else if (!selectedEndDateTime) {
      alert("ERROR: Please select an end date and time before proceeding..");
      return; // Exit the function early
    } else if (
      isNaN(formData.north) ||
      isNaN(formData.south) ||
      isNaN(formData.east) ||
      isNaN(formData.west) ||
      (formData.north > 90) ||
      (formData.south < -90) ||
      (formData.west < -180) ||
      (formData.east > 180)
    ) {
      alert(
        "ERROR: Please select an area on the map or enter FOUR coordinates of interest manually(S,N,W,E) before proceeding..."
      );
      alert(
        "Coordinates should be between -90:90 and -180:180 for (S,N,W,E) respectively..."
      );
      return; // Exit the function early
    }

    formData.requestType = "DownloadAreasTimes";
    formData.startDateTime = selectedStartDateTime;
    formData.endDateTime = selectedEndDateTime;
    formData.downloadOption = downloadOption;
    try {
      setProgress(20);
      setProgressDesc("Downloading Data");
      // console.log(formData);
      // Send request to the backend to fetch both time series data and image data
      const response = await fetch("downloadareastimes/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        // Parse the response as JSON
        const responseData = await response.json();
        const fileName = responseData.fileName;
        alert("Receive file name: " + fileName);
        const fileUrl = `static/media/${fileName}`;
        window.open(fileUrl);
        setProgress(100);
        setProgressDesc("Data Served");
      } else {
        const errorResponse = await response.json();
        setProgress(5);
        setProgressDesc(errorResponse.error, response.status);
        console.error(
          "Failed to download data. HTTP status:",
          response.status,
          "Error message:",
          errorResponse.error
        );
      }
    } catch (error) {
      console.error("Error requesting Time Series:", error);
    }
  };
  const handleHeatMap = async (e) => {
    if (e) e.preventDefault();
    if (formData.variable === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a variable before proceeding..."
      );
      return; // Exit the function early
    }
    else if (selectedEndDateTime.isBefore(selectedStartDateTime)) {
      alert(
        "ERROR: End Date Time Must Be After Than Start Date Time"
      );
      return; // Exit the function early
    }
    else if (formData.temporalLevel === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a temporal level resolution before proceeding..."
      );
      return; // Exit the function early
    } else if (
      formData.temporalLevel !== "hourly" &&
      formData.aggLevel === ""
    ) {
      alert("ERROR: Please select an aggregation method before proceeding...");
      return; // Exit the function early
    } else if (!selectedStartDateTime) {
      alert("ERROR: Please select a start date and time before proceeding.");
      return; // Exit the function early
    } else if (!selectedEndDateTime) {
      alert("ERROR: Please select an end date and time before proceeding..");
      return; // Exit the function early
    } else if (
      isNaN(formData.north) ||
      isNaN(formData.south) ||
      isNaN(formData.east) ||
      isNaN(formData.west) ||
      (formData.north > 90) ||
      (formData.south < -90) ||
      (formData.west < -180) ||
      (formData.east > 180)
    ) {
      alert(
        "ERROR: Please select an area on the map or enter FOUR coordinates of interest manually(S,N,W,E) before proceeding..."
      );
      alert(
        "Coordinates should be between -90:90 and -180:180 for (S,N,W,E) respectively..."
      );
      return; // Exit the function early
    }

    formData.requestType = "Heat Map";
    formData.startDateTime = selectedStartDateTime;
    formData.endDateTime = selectedEndDateTime;

    try {
      // setProgress(20);
      // setProgressDesc("Creating HeatMap");
      // Send request to the backend to fetch both time series data and image data
      const response = await fetch("heatmap/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        // handleVideoUpdate("");
        // Parse the response as JSON
        const responseData = await response.json();
        // console.log("Successfully requested time series data:", responseData);
        setHeatmapImageRecieved(responseData.imageData);
        // setHeatmapImageRecieved(url);
      } else {
        const errorResponse = await response.json();
        setProgress(5);
        setProgressDesc(errorResponse.error, response.status);
        console.error(
          "Failed to fetch heat map data. HTTP status:",
          response.status,
          "Error message:",
          errorResponse.error
        );
      }
    } catch (error) {
      console.error("Error requesting Time Series:", error);
    }
  };
  // console.log(formData.value);
  const handleHeatMapVideo = async (e) => {
    if (e) e.preventDefault();
    if (formData.variable === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a variable before proceeding..."
      );
      return; // Exit the function early
    }
    else if (selectedEndDateTime.isBefore(selectedStartDateTime)) {
      alert(
        "ERROR: End Date Time Must Be After Than Start Date Time"
      );
      return; // Exit the function early
    }
    else if (formData.temporalLevel === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a temporal level resolution before proceeding..."
      );
      return; // Exit the function early
    } else if (
      formData.temporalLevel !== "hourly" &&
      formData.aggLevel === ""
    ) {
      alert("ERROR: Please select an aggregation method before proceeding...");
      return; // Exit the function early
    } else if (!selectedStartDateTime) {
      alert("ERROR: Please select a start date and time before proceeding.");
      return; // Exit the function early
    } else if (!selectedEndDateTime) {
      alert("ERROR: Please select an end date and time before proceeding..");
      return; // Exit the function early
    } else if (
      isNaN(formData.north) ||
      isNaN(formData.south) ||
      isNaN(formData.east) ||
      isNaN(formData.west) ||
      (formData.north > 90) ||
      (formData.south < -90) ||
      (formData.west < -180) ||
      (formData.east > 180)
    ) {
      alert(
        "ERROR: Please select an area on the map or enter FOUR coordinates of interest manually(S,N,W,E) before proceeding..."
      );
      alert(
        "Coordinates should be between -90:90 and -180:180 for (S,N,W,E) respectively..."
      );
      return; // Exit the function early
    }

    formData.requestType = "Heat Map Video";
    formData.startDateTime = selectedStartDateTime;
    formData.endDateTime = selectedEndDateTime;

    try {
      setProgress(20);
      setProgressDesc("Creating HeatMap Video");
      // Send request to the backend to fetch both time series data and image data
      const response = await fetch("heatmapVideo/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        const videoBlob = await response.blob();
        setVideoReceived(videoBlob);
      } else {
        const errorResponse = await response.json();
        setProgress(5);
        setProgressDesc(errorResponse.error, response.status);
        console.error(
          "Failed to fetch heat map data. HTTP status:",
          response.status,
          "Error message:",
          errorResponse.error
        );
      }
    } catch (error) {
      console.error("Error requesting HeatMap Video:", error);
    }
  };
  // console.log(formData.value);

  const handleTimeSeries = async (e) => {
    if (e) e.preventDefault();
    isNaN(variable)
    if (formData.variable === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a variable before proceeding..."
      );
      return; // Exit the function early
    }
    else if (selectedEndDateTime.isBefore(selectedStartDateTime)) {
      alert(
        "ERROR: End Date Time Must Be After Than Start Date Time"
      );
      return; // Exit the function early
    }
    else if (formData.temporalLevel === "") {
      // If not, display an error message or perform any other action to prompt the user to select a temporal level
      alert(
        "ERROR: Please select a temporal level resolution before proceeding..."
      );
      return; // Exit the function early
    } else if (!selectedStartDateTime) {
      alert("ERROR: Please select a start date and time before proceeding.");
      return; // Exit the function early
    } else if (!selectedEndDateTime) {
      alert("ERROR: Please select an end date and time before proceeding..");
      return; // Exit the function early
    } else if (
      isNaN(formData.north) ||
      isNaN(formData.south) ||
      isNaN(formData.east) ||
      isNaN(formData.west) ||
      (formData.north > 90) ||
      (formData.south < -90) ||
      (formData.west < -180) ||
      (formData.east > 180)
    ) {
      alert(
        "ERROR: Please select an area on the map or enter FOUR coordinates of interest manually(S,N,W,E) before proceeding..."
      );
      alert(
        "Coordinates should be between -90:90 and -180:180 for (S,N,W,E) respectively..."
      );
      return; // Exit the function early
    }

    formData.requestType = "Time Series";
    formData.startDateTime = selectedStartDateTime;
    formData.endDateTime = selectedEndDateTime;
    try {

      // console.log(formData);
      // Send request to the backend to fetch both time series data and image data
      const response = await fetch("timeseries/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the response is successful
      if (response.ok) {
        // Parse the response as JSON
        const responseData = await response.json();
        // console.log("Successfully requested time series data:", responseData);
        setImageRecieved(responseData);
      } else {
        const errorResponse = await response.json();
        setProgress(5);
        setProgressDesc(errorResponse.error, response.status);
        console.error(
          "Failed to fetch time series data. HTTP status:",
          response.status,
          "Error message:",
          errorResponse.error
        );
      }
    } catch (error) {
      console.error("Error requesting Time Series:", error);
    }
  };
  useEffect(() => {
    if (drawnShapeBounds) {
      const north_val = drawnShapeBounds._northEast.lat;
      const east_val = drawnShapeBounds._northEast.lng;
      const south_val = drawnShapeBounds._southWest.lat;
      const west_val = drawnShapeBounds._southWest.lng;

      setFormData((prevFormData) => ({
        ...prevFormData,
        north: north_val,
        east: east_val,
        south: south_val,
        west: west_val,
      }));
    }
    // if (variable){
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     variable: variable, // Update formData.variable with the selected variable
    //   }));
    // }
  }, [drawnShapeBounds]);

  useEffect(() => {

    if (variable) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        variable: variable, // Update formData.variable with the selected variable
      }));
    }
  }, [variable]);
  useEffect(() => {

    if (secondAgg) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        secondAgg: secondAgg, // Update formData.variable with the selected variable
      }));
    }
  }, [secondAgg]);
  useEffect(() => {

    if (comparison) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        comparison: comparison, // Update formData.variable with the selected variable
      }));
    }
  }, [comparison]);
  useEffect(() => {

    if (myValue) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        value: myValue, // Update formData.variable with the selected variable
      }));
    }
  }, [myValue]);
  useEffect(() => {
    if (heatmapimageRecieved) {

      setheatmapImageSrc(heatmapimageRecieved);
      setProgress(100);
      setProgressDesc("TimeSeries & HeatMap Created");
      handleExpandClickFirst(false);
      handleExpandClickSecond(false);


    }
  }, [heatmapimageRecieved, setheatmapImageSrc]);
  useEffect(() => {
    if (videoRecieved) {

      setVideoUrl(videoRecieved);
      setProgress(100);
      setProgressDesc("HeatMap Video Created");
      handleExpandClickFirst(true);
      handleExpandClickSecond(false);
    }
  }, [videoRecieved, setVideoUrl]);
  // }, [videoRecieved, handleVideoUpdate]);
  // console.log("Hey,",variable);
  useEffect(() => {
    if (imageRecieved) {
      // Execute any code you want to run after responseReceived changes
      // This code will run every time responseReceived changes
      // For example, you can trigger a re-render here or perform other actions
      // console.log("Image received:");
      setImageSrc(imageRecieved);
      setProgress(50);
      setProgressDesc("TimeSeries Done; Creating HeatMap Now");
      // Update progress to 100 when response is received
      // setProgress(100);
      // setProgressDesc("Created Time Series");
    }
  }, [imageRecieved, setImageSrc]);
  // }, [imageRecieved, handleImageUpdate]);
  useEffect(() => {
    if (areaRecieved) {
      // Execute any code you want to run after responseReceived changes
      // This code will run every time responseReceived changes
      // For example, you can trigger a re-render here or perform other actions
      // console.log("Image received:");
      setJsonFile(areaRecieved);
      setProgress(100);
      setProgressDesc("Areas & Times Recieved");
      handleExpandClickSecond(true);
      // handleQueryType(formData.requestType);
      // Update progress to 100 when response is received
      // setProgress(100);
      // setProgressDesc("Received Areas On Map");
    }
  }, [areaRecieved, setJsonFile]);
  // }, [areaRecieved, handleAreaQuery]);
  useEffect(() => {
    if (formData.requestType) {
      // Execute any code you want to run after responseReceived changes
      // This code will run every time responseReceived changes
      // For example, you can trigger a re-render here or perform other actions
      // console.log("Image received:");
      // handleTimeQuery(timeRecieved);
      setrequestType(formData.requestType);
      // Update progress to 100 when response is received
      // setProgress(100);
      // setProgressDesc("Received Times On Plot");
    }
  }, [formData.requestType, setrequestType]);
  // [formData.requestType, handleQueryType]);
  useEffect(() => {
    if (timeRecieved) {
      // Execute any code you want to run after responseReceived changes
      // This code will run every time responseReceived changes
      // For example, you can trigger a re-render here or perform other actions
      // console.log("Image received:");
      setJsonFileTimeQuery(timeRecieved);
      setProgress(50);
      setProgressDesc("Times Recieved; Getting Areas Now");
      // handleQueryType(formData.requestType);
      // Update progress to 100 when response is received
      // setProgress(100);
      // setProgressDesc("Received Times On Plot");
    }
  }, [timeRecieved, setJsonFileTimeQuery]);
  // }, [timeRecieved, handleTimeQuery]);

  useEffect(() => {
    if (tableRecieved) {
      // Execute any code you want to run after responseReceived changes
      // This code will run every time responseReceived changes
      // For example, you can trigger a re-render here or perform other actions
      // console.log("Image received:");
      setTableDataM(tableRecieved);
      // Update progress to 100 when response is received
      // setProgress(100);
      // setProgressDesc("Received Areas Data on Lower Table");

    }
  }, [tableRecieved, setTableDataM]);
  // }, [tableRecieved, handleTable]);

  useEffect(() => {
    if (tableTimesRecieved) {
      // Execute any code you want to run after responseReceived changes
      // This code will run every time responseReceived changes
      // For example, you can trigger a re-render here or perform other actions
      // console.log("Image received:");
      setTableDataTimes(tableTimesRecieved);
      // Update progress to 100 when response is received
      // setProgress(100);
      // setProgressDesc("Received Times Data on Upper Table");
    }
  }, [tableTimesRecieved, setTableDataTimes]);
  //  }, [tableTimesRecieved, handleTableTimes]);
  const isAggregationDisabled = formData.temporalLevel === 'hourly';
  const handleQueryClick = () => {

    handleTimeSeries();

    handleHeatMap();
    // setExpandedFirst(true);
  };
  const handleGetAreasTimesClick = () => {
    handleTimes();

    handleAreas();

  };
  return (

    // <ThemeProvider theme={theme}>
    <div className={classes.root}>
      <CssBaseline />


      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >

        <Box
          component="ul"
          sx={{ display: "flex", gap: 0.25, flexWrap: "wrap", p: 1, mb: 1, mt: 0.5, marginTop: "50px", marginLeft: "-10px" }}
        >
          <div className="sidebar-content2" >
            <div className="sidebar-container2">
              <div className="nine" style={{ backgroundColor: "black", color: 'white', marginLeft: '-20px', marginRight: '-8px', marginBottom: '0px', marginTop: "-30px" }}>
                <h2 style={{ color: 'white', margin: 0, padding: "0px 0" }}>iHARPV</h2>
              </div>
              <div class="instruction-text">
                <h5>
                  Explore <strong>iHARPV</strong> by selecting an area on the map and Variable, Temporal Range from below.
                </h5>
              </div>

              {/* Flex column to stack items vertically */}
              {/* <CollapsibleCard sx={{ marginLeft: "-50px", height: "500px" }}> */}
              {/* <div className="collapsible-card-content"> */}
              <Box sx={{ marginRight: 0, marginTop: 1, marginLeft: -2 }}>
                <VariablesDropDown personName={variable} handleChange={handleChangeDropDown} />
              </Box>
              <div className="datetime-container">
                <Box sx={{ marginLeft: -2, marginRight: 0, marginTop: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      views={['year', 'day', 'hours']}
                      name="startDateTime"
                      disableFuture
                      label="Start Date & Time"
                      ampm={false} // Disable AM/PM selector
                      minutesStep={0} // Set minutes step to 60 to skip minutes selection
                      secondsStep={0} // Set seconds step to 0 to remove seconds
                      value={selectedStartDateTime}
                      sx={{ width: "200px", marginLeft: "0px" }}
                      timezone="UTC"
                      maxDateTime={maxDate}
                      minDateTime={minDate}
                      onChange={(newDateTime) => setSelectedStartDateTime(newDateTime)}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ marginLeft: -2, marginRight: 0, marginTop: 1 }}>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      views={['year', 'day', 'hours']}
                      disableFuture
                      label="End Date & Time"
                      ampm={false} // Disable AM/PM selector
                      minutesStep={60} // Set minutes step to 60 to skip minutes selection
                      secondsStep={0} // Set seconds step to 0 to remove
                      name="endDateTime"
                      value={selectedEndDateTime}
                      timezone="UTC"
                      maxDateTime={maxDate}
                      minDateTime={minDate}
                      sx={{ width: "200px", marginLeft: "0px" }}
                      onChange={(newDateTime) => setSelectedEndDateTime(newDateTime)}
                    />
                  </LocalizationProvider>
                </Box>
              </div>
              <Box component="ul"
                sx={{ display: "flex", gap: 1, flexWrap: "wrap", p: 0, mb: 0.25, mt: 0.25, marginTop: "15px", marginLeft: "-12px" }}>

                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleQueryClick}
                >
                  Query
                </Button>

                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleDownload}
                >
                  Download
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleHeatMapVideo}

                >
                  HeatMap
                </Button>
              </Box>

              {/* </div> */}
              {/* </CollapsibleCard>
          </Box> */}
            </div>
          </div>

          <Box sx={{ marginLeft: 0, marginRight: 0, marginTop: 0, border: "solid 3px" }}>
            <MyMap />
          </Box>
          <div className="sidebar-content" >


            <div className="nine" style={{ backgroundColor: "black", color: 'white', marginLeft: '-15px', marginRight: '-5px', marginBottom: '-10px', marginTop: "-20px" }}>
              <h2 style={{ color: 'white' }}>iHARPV</h2>
            </div>

            <div style={{ marginBottom: "-20px", marginTop: "20px" }}></div>

            <div className="sidebar-container">
              <div style={{ marginLeft: "0px" }}>

                <div style={{ marginBottom: "5px" }}>

                </div>
                {/* <Box></Box> */}
                <div style={{ marginBottom: "-16px", marginTop: "-5px" }}>
                  <h4 className="sidebar-heading">Temporal Resolution</h4>
                  <div style={{ marginLeft: "-10px" }}>
                    <Form label="Select Output Level">
                      <div className="mb-4">
                        <Form.Check
                          inline
                          label="hourly"
                          name="temporalLevel"
                          type="radio"
                          id="inline-radio-1"
                          value="hourly" // Set value of radio input to hourly
                          style={{ fontSize: "small", marginLeft: "5px" }}
                          onChange={handleChange}
                          checked={formData.temporalLevel === "hourly"}
                        />
                        <Form.Check
                          inline
                          label="daily"
                          name="temporalLevel"
                          type="radio"
                          id="inline-radio-2"
                          value="daily" // Set value of radio input to Daily
                          style={{ fontSize: "small" }}
                          onChange={handleChange}
                          checked={formData.temporalLevel === "daily"}
                        />
                        <Form.Check
                          inline
                          name="temporalLevel"
                          label="monthly"
                          type="radio"
                          id="inline-radio-3"
                          value="monthly" // Set value of radio input to monthly
                          style={{ fontSize: "small" }}
                          onChange={handleChange}
                          checked={formData.temporalLevel === "monthly"}
                        />
                        <Form.Check
                          inline
                          name="temporalLevel"
                          label="yearly"
                          type="radio"
                          id="inline-radio-4"
                          value="yearly" // Set value of radio input to yearly
                          style={{ fontSize: "small" }}
                          onChange={handleChange}
                          checked={formData.temporalLevel === "yearly"}
                        />
                      </div>
                    </Form>
                  </div>
                </div>
                <div style={{ marginTop: "-25px" }}>

                  <h4 className="sidebar-heading">
                    Temporal Aggregation
                  </h4>
                  <div style={{ marginLeft: "0px" }}>
                    <Form>
                      {["radio"].map((type) => (
                        <div key={`inline-${type}`} className="mb-3">
                          <Form.Check
                            inline
                            label="min"
                            name="aggLevel"
                            type={type}
                            id={`inline-2-${type}-1`}
                            value="min" // Set value of radio input to Minimum
                            style={{ fontSize: "small", marginLeft: "10px" }}
                            onChange={handleChange} // Add onChange handler
                            checked={formData.aggLevel === "min"}
                            disabled={isAggregationDisabled}
                          />
                          <Form.Check
                            inline
                            label="max"
                            name="aggLevel"
                            type={type}
                            id={`inline-2-${type}-2`}
                            value="max" // Set value of radio input to Maximum
                            style={{ fontSize: "small" }}
                            onChange={handleChange} // Add onChange handler
                            checked={formData.aggLevel === "max"}
                            disabled={isAggregationDisabled}
                          />
                          <Form.Check
                            inline
                            label="mean"
                            name="aggLevel"
                            type={type}
                            id={`inline-2-${type}-3`}
                            value="mean" // Set value of radio input to Average
                            style={{ fontSize: "small" }}
                            onChange={handleChange} // Add onChange handler
                            checked={formData.aggLevel === "mean"}
                            disabled={isAggregationDisabled}
                          />
                        </div>
                      ))}
                    </Form>
                  </div>
                </div>
                <div style={{ marginTop: "-20px" }}>
                  <h4 className="sidebar-heading">Spatial Resolution</h4>
                  <div style={{ marginLeft: "0px" }}>
                    <Form>
                      {["radio"].map((type) => (
                        <div key={`inline-${type}`} className="mb-5">
                          <Form.Check
                            inline
                            label="0.25"
                            name="spatialLevel"
                            type={type}
                            id={`inline-2-${type}-1`}
                            value="0.25" // Set value of radio input to Minimum
                            style={{ fontSize: "small", marginLeft: "10px" }}
                            onChange={handleChange} // Add onChange handler
                            checked={formData.spatialLevel === "0.25"}
                          // disabled
                          />
                          <Form.Check
                            inline
                            label="0.5"
                            name="spatialLevel"
                            type={type}
                            id={`inline-2-${type}-2`}
                            value="0.5" // Set value of radio input to Maximum
                            style={{ fontSize: "small" }}
                            onChange={handleChange} // Add onChange handler
                            checked={formData.spatialLevel === "0.5"}
                          // disabled
                          />
                          <Form.Check
                            inline
                            label="1.0"
                            name="spatialLevel"
                            type={type}
                            id={`inline-2-${type}-3`}
                            value="1.0" // Set value of radio input to Average
                            style={{ fontSize: "small" }}
                            onChange={handleChange} // Add onChange handler
                            checked={formData.spatialLevel === "1.0"}
                          // disabled
                          />
                          <Form.Check
                            inline
                            label="2.0"
                            name="spatialLevel"
                            type={type}
                            id={`inline-2-${type}-3`}
                            value="2.0" // Set value of radio input to Average
                            style={{ fontSize: "small" }}
                            onChange={handleChange} // Add onChange handler
                            checked={formData.spatialLevel === "2.0"}
                          // disabled
                          />
                        </div>
                      ))}
                    </Form>
                  </div>
                </div>
                <div style={{ marginBottom: "-53px" }}></div>

                <h4 className="sidebar-heading">
                  Longitude Latitude Range
                </h4>
                <div className="coordinates-container">
                  <div style={{ marginLeft: "5px", marginTop: "-12px", marginRight: "20px", marginBottom: "-5px" }}>
                    <div className="row">


                      <div style={{ marginTop: "2px", marginRight: "35px", marginBottom: "8px" }}>
                        <div className="cell">
                          <label className="label"> North</label>
                          <input
                            type="number"
                            name="north"
                            value={formData.north}
                            onChange={handleChange}
                            className="input"
                            max="90"
                            min="-90"
                            style={{ fontSize: '12px' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="column">
                      <div className="cell">
                        <div style={{ marginLeft: "5px", marginRight: "30px", marginTop: "-25px" }}>
                          <label className="label" style={{ marginLeft: "28px" }}>West</label>
                          <input
                            name="west"
                            value={formData.west}
                            onChange={handleChange}
                            className="input"
                            type="number"
                            max="180"
                            min="-180"
                            style={{ fontSize: '12px' }}
                          />
                        </div>
                      </div>
                      <div style={{ marginLeft: "25px", marginRight: "20px", marginTop: "-25px" }}>
                        <div className="cell">
                          <label className="label" style={{ marginLeft: "15px" }}>East</label>
                          <input
                            name="east"
                            value={formData.east}
                            onChange={handleChange}
                            className="input"
                            type="number"
                            max="180"
                            min="-180"
                            style={{ fontSize: '12px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "10px", marginBottom: "0px", marginRight: "29px", marginTop: "-10px" }}>
                    <div className="row">
                    </div>
                    <div className="cell">
                      <label className="label">South</label>

                      <input
                        name="south"
                        value={formData.south}
                        onChange={handleChange}
                        className="input"
                        type="number"
                        max="90"
                        min="-90"
                        style={{ fontSize: '12px' }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom: "0px" }}></div>

              </div>
              <Divider
                sx={{
                  // height: 1,
                  margin: '5px 0px -1px 0px',
                  border: '1px solid #ccc',

                  // borderTop: '1px dashed #bbb',
                }}
              />
              <div
                style={{
                  marginTop: "2px", marginLeft: "0px", maringBottom: "-10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-20px",
                    marginBottom: "-5px"
                  }}
                >
                  <SecondAggDropDown personName={secondAgg} handleChange={handleChangeSecondAggDropDown} />
                </div>
                <div
                  style={{
                    marginLeft: "-20px",
                    marginRight: "-5px",
                    marginBottom: "-5px"
                  }}
                >
                  <ComparisonsDropDown personName={comparison} handleChange={handleChangeComparisonDropDown} />
                </div>
                <div
                  style={{
                    marginTop: "9px",
                    marginLeft: "-20px",
                    marginBottom: "-5px"
                  }}
                >
                  <QuantityInput myValue={myValue} onChange={handleValueChange} />
                </div>
              </div>
              <div
                className="sidebar-buttons"
                style={{ display: "flex", gap: "2px", position: "left", marginTop: "10px", marginLeft: "75px" }}
              >
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={handleGetAreasTimesClick}
                // disabled
                >
                  Get Areas & Times
                </Button>

              </div>
              <div
                style={{
                  marginTop: "0px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >


                <p style={{ fontSize: "13px" }}>{progressDesc}</p>
              </div>
              <div
                style={{
                  marginTop: "-17px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >

                {/* TODO: Look at this website later to do the progress bar  */}
                <ProgressBar
                  // animated
                  now={progress} // Set progress dynamically
                  label={`Progress: ${progress}%`}
                  style={{ width: "100%", height: "85%" }}
                />
              </div>

            </div>
          </div>
        </Box>
        <Box
          component="ul"
          sx={{ display: "flex", gap: 0.5, flexWrap: "wrap", p: 0, mb: 1, mt: 0.5, marginTop: "-15px", marginLeft: "5px", height: "250px" }}
        >

          <CollapsibleCard title="Image" className="collapsible-card-max-height" expanded={expandedThird} onExpandClick={handleExpandClickThird}>
            <TimeSeriesComponent imageData={imageSrc} />
          </CollapsibleCard>
          <CollapsibleCard title="HeatMapImage" className="collapsible-card-max-height" expanded={expandedFourth} onExpandClick={handleExpandClickFourth}>
            <ImageComponent imageData={heatmapImageSrc} />
          </CollapsibleCard>
          <CollapsibleCard title="Video" sx={{ height: "225px" }} expanded={expandedFirst} onExpandClick={handleExpandClickFirst}>
            <HeatMapComponent videoSrc={videoUrl} className="collapsible-card-max-height" />
          </CollapsibleCard>
        </Box>
        <Box
          component="ul"
          sx={{ display: "flex", gap: 0.5, flexWrap: "wrap", p: 0, m: 0, marginTop: "20px", marginLeft: "5px" }}
        >

          <CollapsibleCardLower title="Times" className="collapsible-card-max-height" expanded={expandedSecond} onExpandClick={handleExpandClickSecond}>
            <TimesComponent imageData={jsonFileTimeQuery} />
          </CollapsibleCardLower>
          <CollapsibleCardLower title="Table" className="collapsible-card-max-height" expanded={expandedSecond} onExpandClick={handleExpandClickSecond}>
            <TableTimesComponent tableData={tableDataTimes} />
          </CollapsibleCardLower>
          <CollapsibleCardLower title="Plotly Figure" className="collapsible-card-max-height" expanded={expandedSecond} onExpandClick={handleExpandClickSecond}>
            <AreasComponentMap jsonData={jsonFile} />
          </CollapsibleCardLower>
          <CollapsibleCardLower title="Table" className="collapsible-card-max-height" expanded={expandedSecond} onExpandClick={handleExpandClickSecond}>
            <TableComponent tableData={tableDataM} />
          </CollapsibleCardLower >





        </Box >
      </main >
    </div >
    // </ThemeProvider >
  );
}
